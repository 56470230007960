<template>
    <div>
        <div
            class="asset-image-control"
            :style="{
                backgroundImage: uploadedImageUrl
                    ? 'none'
                    : `url(${pictureUrl ||
                          trackerAssetTypePicture(typeIdentifier)})`,
            }"
        >
            <cropper
                v-if="uploadedImageUrl"
                ref="cropper"
                auto-sizing
                canvas-color="#f8f8f8"
                disable-click-to-choose
                prevent-white-space
                :show-remove-button="false"
                :zoom-speed="5"
                :quality="3"
                @move="handleCropperUpdate"
                @new-image-drawn="emitChange"
                @zoom="handleCropperUpdate"
            >
                <img slot="initial" :src="uploadedImageUrl" />
            </cropper>

            <div class="l-inline l-center control-drawer">
                <upload-icon width="26" height="26" color="white" />

                <input
                    type="file"
                    accept="image/jpeg, image/png"
                    @change="handleFileUpload"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Cropper from 'vue-croppa'
import debounce from 'lodash.debounce'

import AssetHelpers from '@/mixins/AssetHelpers'

import UploadIcon from './icons/UploadIcon'

export default {
    name: 'AssetImageControl',
    components: {
        Cropper: Cropper.component,
        UploadIcon,
    },
    mixins: [AssetHelpers],
    props: {
        pictureUrl: {
            type: String,
            default: null,
        },
        typeIdentifier: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            uploadedImageName: null,
            uploadedImageUrl: null,
        }
    },
    methods: {
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return
            }

            const uploadedFile = e.target.files[0]

            this.uploadedImageName = uploadedFile.name
            this.uploadedImageUrl = URL.createObjectURL(uploadedFile)

            this.$nextTick(() => this.$refs.cropper.refresh())
        },
        handleCropperUpdate: debounce(function() {
            this.emitChange()
        }, 750),
        emitChange() {
            this.$refs.cropper.generateBlob(
                data => {
                    this.$emit('change', {
                        name: this.uploadedImageName,
                        data,
                    })
                },
                'image/jpeg',
                0.75
            )
        },
    },
}
</script>

<style lang="scss" scoped>
.asset-image-control {
    position: relative;
    width: 100%;
    height: 200px;
    background-color: $color-gray-lighter;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;

    &:active,
    &:hover {
        .control-drawer {
            bottom: 0;
            box-shadow: 0 -8px 40px rgba(255, 255, 255, 0.125);
        }
    }
}

.control-drawer {
    position: absolute;
    bottom: -50px;
    background-color: rgba(0, 0, 0, 0.375);
    width: 100%;
    height: 50px;
    transition: all 0.1s ease-out;

    &:active,
    &:focus,
    &:hover {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.croppa-container {
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}

input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    opacity: 0;
    cursor: pointer;

    &::-webkit-file-upload-button {
        cursor: pointer;
    }
}

@include respond-to('for-mobile-down') {
    .control-drawer {
        bottom: 0;
    }
}
</style>
