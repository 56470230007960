export default {
    methods: {
        trackerAssetTypePicture(identifier) {
            return this.$store.state.tracker?.assetTypes?.find(
                assetType =>
                    assetType.identifier.localeCompare(identifier) === 0
            )?.picture?.url
        },
        logo() {
            return require(`@/assets/${process.env.VUE_APP_LOGO_ASSET_PATH}`)
        },
    },
}
