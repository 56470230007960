<template>
    <Multiselect
        v-model="selectedAddress"
        :options="addresses"
        track-by="id"
        label="place_name"
        :searchable="true"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="true"
        :placeholder="$t('typeToSearch')"
        :multiple="false"
        :allow-empty="false"
        :close-on-select="true"
        :hide-selected="false"
        :show-labels="false"
        @search-change="asyncFind"
        @input="$emit('input', selectedAddress)"
    >
        <template #option="{ option }">
            <div class="option">
                <span>{{ option.place_name }}</span>
            </div>
        </template>

        <template #noOptions>
            {{ $t('noOptions') }}
        </template>

        <template #noResult>
            {{ $t('noResult') }}
        </template>
    </Multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'
import debounce from 'lodash.debounce'

export default {
    name: 'UserSelect',
    components: {
        Multiselect,
    },
    props: {
        proximityLongitude: {
            type: [String, Number],
            default: null,
        },
        proximityLatitude: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            addresses: [],
            selectedAddress: {},
            isLoading: false,
        }
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {
        asyncFind: debounce(function(query) {
            if (query.length <= 0) {
                return
            }

            let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${process.env.VUE_APP_MAPBOX_TOKEN}`
            if (this.proximityLongitude && this.proximityLatitude) {
                url =
                    url +
                    `&proximity=${this.proximityLongitude},${this.proximityLatitude}`
            }
            this.isLoading = true
            axios.get(url).then(response => {
                this.addresses = response.data.features
                this.isLoading = false
            })
        }, 400),
    },
}
</script>

<i18n>
    {
        "en": {
            "noResult": "No results were found",
            "typeToSearch": "Type to search address",
            "noOptions": "Start to type to get address results"
        },
        "de": {
            "noResult": "Keine Resultate gefunden",
            "typeToSearch": "Hier nach Adresse suchen",
            "noOptions": "Starte zu tippen um Adressen anzuzeigen"
        },
        "it": {
            "noResult": "Non sono stati trovati risultati",
            "typeToSearch": "Digitare l'indirizzo da cercare",
            "noOptions": "Inizia a digitare per ottenere i risultati dell'indirizzo"
        }
    }
</i18n>
