<template>
    <div>
        <slot>
            <ax-button @click="$emit('save')">
                {{ $t('save') }}
            </ax-button>
        </slot>
    </div>
</template>

<script>
import AxButton from './AxButton'

export default {
    name: 'FormNavigation',
    components: {
        AxButton,
    },
}
</script>

<i18n>
{
    "en": {
        "save": "Save"
    },
    "de": {
        "save": "Speichern"
    },
    "it": {
        "save": "Salvare"
    }
}
</i18n>
